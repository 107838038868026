<template>
    <Input
        id="calendar-input-hotel"
        :value="store.selectedBeginDate ? formatDate(store.selectedBeginDate) : null"
        label="Дата заезда"
        placeholder="Выберите дату"
        :no-pointer="true"
        icon="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M13.3333 2.5V5.83333M6.66659 2.5V5.83333M3.33325 9.16667H16.6666M3.33325 5.83333C3.33325 5.39131 3.50885 4.96738 3.82141 4.65482C4.13397 4.34226 4.55789 4.16667 4.99992 4.16667H14.9999C15.4419 4.16667 15.8659 4.34226 16.1784 4.65482C16.491 4.96738 16.6666 5.39131 16.6666 5.83333V15.8333C16.6666 16.2754 16.491 16.6993 16.1784 17.0118C15.8659 17.3244 15.4419 17.5 14.9999 17.5H4.99992C4.55789 17.5 4.13397 17.3244 3.82141 17.0118C3.50885 16.6993 3.33325 16.2754 3.33325 15.8333V5.83333ZM6.66659 12.5H8.33325V14.1667H6.66659V12.5Z' stroke='#9E9E9E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>"
        :error="errorBeginDate"
        @click="$emit('open')"
    />
    <Input
        :value="store.selectedEndDate ? formatDate(store.selectedEndDate) : null"
        label="Дата выезда"
        placeholder="Выберите дату"
        :no-pointer="true"
        icon="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M13.3333 2.5V5.83333M6.66659 2.5V5.83333M3.33325 9.16667H16.6666M3.33325 5.83333C3.33325 5.39131 3.50885 4.96738 3.82141 4.65482C4.13397 4.34226 4.55789 4.16667 4.99992 4.16667H14.9999C15.4419 4.16667 15.8659 4.34226 16.1784 4.65482C16.491 4.96738 16.6666 5.39131 16.6666 5.83333V15.8333C16.6666 16.2754 16.491 16.6993 16.1784 17.0118C15.8659 17.3244 15.4419 17.5 14.9999 17.5H4.99992C4.55789 17.5 4.13397 17.3244 3.82141 17.0118C3.50885 16.6993 3.33325 16.2754 3.33325 15.8333V5.83333ZM6.66659 12.5H8.33325V14.1667H6.66659V12.5Z' stroke='#9E9E9E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>"
        :error="errorEndDate"
        @click="$emit('open')"
    />

    <div
        class="inputs__input inputs__input--mobile-w-100"
        v-if="guest"
    >
        <div class="inputs__label">Список гостей</div>
        <Select
            :store="store"
            :error="errorGuest"
            class="form__select"
        />
        <span class="inputs__error">{{errorGuest}}</span>
    </div>
</template>
<script>
import Input from '@/components/inputs/Input.vue';
import Select from '@/components/search/common/Select.vue';
import { formatDate } from '@/components/search/calendar.js';

export default {
    data() {
        return {
            calendar: null,
            isMobile: false,
            isCalendarOpen: false,
            isChildSeletOpen: false,
            isWatchingSelectedDate: true,
            isWatchingSelectedYearAndMonth: true,
        };
    },
    components: { Select, Input },
    props: {
        store: Object,
        errorBeginDate: [Boolean, String],
        errorEndDate: [Boolean, String],
        errorGuest: [Boolean, String],
        guest: Boolean,
    },
    emits: ['open'],

    methods: { formatDate },
};
</script>
<style lang="scss" scoped>
@use '@/components/hotel/hotel-detail.scss' as *;
</style>
