<template>
    <CalendarWidgetSearch
        ref="rootEl"
        v-if="mode === 'widget-search'"
        :store="store"
        :error-begin-date="errorBeginDate"
        :error-end-date="errorEndDate"
        @open="onOpen"
        @find="$emit('find')"
    />
    <CalendarSearch
        ref="rootEl"
        v-else-if="mode === 'search'"
        :store="store"
        :error-begin-date="errorBeginDate"
        :error-end-date="errorEndDate"
        @open="onOpen"
        @find="$emit('find')"
    />
    <CalendarHotel
        ref="rootEl"
        v-else-if="mode === 'hotel'"
        :store="store"
        :error-begin-date="errorBeginDate"
        :error-end-date="errorEndDate"
        :error-guest="errorGuest"
        :guest="guest"
        @open="onOpen"
    />
    <Overlay
        class="vanilla-overlay"
        id="calendarOverlay"
    />
</template>

<script>
import VanillaCalendar from 'vanilla-calendar-pro';
import { computed, createApp } from 'vue';
import Button from '@/components/button/Button.vue';
import MobileSelect from './common/MobileSelect.vue';
import { formatYearAndMonth, generateDaysInMonthAndNextMonth, formatDateAsString } from '@/js/date';
import { formatCount } from '@/js/format';
import CalendarWidgetSearch from '@/components/search/templates/CalendarWidgetSearch.vue';
import CalendarSearch from '@/components/search/templates/CalendarSearch.vue';
import CalendarHotel from '@/components/search/templates/CalendarHotel.vue';
import Overlay from '@/components/overlay/Overlay.vue';
import { formatDate } from '@/components/search/calendar.js';

// тут ошибка самого вью, тк. не любит он монтировать несколько приложений.
function initVueComponent(instance) {
    if (instance.appMobileSelect !== null) {
        instance.appMobileSelect.unmount();
    }
    instance.appMobileSelect = createApp(MobileSelect, {
        store: instance.store,
        canGuest: ['widget-search', 'search'].includes(instance.mode),
    });
    instance.appMobileSelect.mount('#mobileChildSelect');

    const app2 = createApp(Button, {
        buttonText: 'Найти',
        class: 'mobile-select-button active',
        handleClick: instance.onMobileFindClick,
    });
    app2.mount('#mobileChildButton');
}

/*TODO: есть много логики внутри - changeToInput
если мы ресайзим экран. Текста обнуляются, но даты выбранные остаются. нужно ее продублировать в updateCalendar по идее.
итак я что-то много логики затронул.
+ при сбросе не обновляются инпуты на мобилке

*/

const getParams = instance => {
    return {
        input: true,
        actions: {
            clickArrow(_, self) {
                instance.isWatchingSelectedYearAndMonth = false;
                instance.$emit('updateSelectedYearAndMonth', formatYearAndMonth(self.selectedYear, self.selectedMonth));
                instance.$nextTick(() => {
                    instance.isWatchingSelectedYearAndMonth = true;
                });
            },
            changeToInput(_e, self) {
                instance.store.selectedBeginDate =
                    (self.selectedDates?.length >= 1 ? self.selectedDates[0] : null) ?? null;
                instance.store.selectedEndDate =
                    (self.selectedDates?.length >= 1 ? self.selectedDates[1] : null) ?? null;

                if (!self.HTMLInputElement) return;

                const isMobile = window.innerWidth < 1024;

                // для мобильного инпут
                if (self.selectedDates?.length) {
                    if (isMobile) {
                        const infoTitle = document.querySelector('.vanilla-calendar-info__title');
                        const childSelectCalendar = document.querySelector('.mobile-select__calendar');
                        const childSelectText = childSelectCalendar?.querySelector('.mobile-select__text');
                        const MobileSelectButton = document.querySelector('#mobileChildButton .mobile-select-button');

                        if (self.selectedDates[0]) {
                            if (infoTitle) {
                                infoTitle.textContent = 'Выезд';
                            }
                        } else {
                            if (infoTitle) {
                                infoTitle.textContent = 'Заезд';
                            }

                            if (childSelectText) {
                                childSelectText.textContent = 'Заезд';
                            }
                        }

                        if (self.selectedDates[0] && self.selectedDates[1]) {
                            if (['widget-search', 'search'].includes(instance.mode)) {
                                const mobileSelect = document.querySelector('#mobile-select-' + instance.mode);
                                if (mobileSelect) {
                                    mobileSelect.textContent = `${formatDate(
                                        self.selectedDates[0],
                                    )} - ${formatDate(self.selectedDates[1])}`;
                                }
                            }

                            if (infoTitle) {
                                infoTitle.textContent = 'Заезд–Выезд';
                            }

                            if (childSelectText) {
                                childSelectText.textContent = 'Заезд–Выезд';
                            }
                            if (MobileSelectButton) {
                                //MobileSelectButton.classList.add('active');
                            }
                        } else {
                            if (MobileSelectButton) {
                                //MobileSelectButton.classList.remove('active');
                            }
                        }
                    }

                    // для ПК инпутов
                    const firstInput = document.querySelector('.search__input_first');
                    const secondInput = document.querySelector('.search__input_second');

                    if (firstInput && secondInput) {
                        if (self.selectedDates[0]) {
                            firstInput.classList.remove('hover');
                            secondInput.classList.add('hover');
                        }
                        if (self.selectedDates[1]) {
                            secondInput.classList.remove('hover');
                        }
                        if (!self.selectedDates[0] && !self.selectedDates[1]) {
                            secondInput.classList.remove('hover');
                        }
                    }

                    const start = document
                        .querySelector('#calendar-input-' + instance.mode)
                        .parentElement.querySelector('#vanilaStart');
                    if (start) {
                        //start.classList.remove('error');
                        start.textContent = formatDate(self.selectedDates[0]);
                    }

                    const end = document
                        .querySelector('#calendar-input-' + instance.mode)
                        .parentElement.querySelector('#vanilaEnd');
                    if (end) {
                        //end.classList.remove('error');
                        end.textContent = formatDate(self.selectedDates[1]);
                    }

                    instance.ensureFooterDates();

                    return;
                }

                self.HTMLInputElement.value = '';
            },
            hideCalendar(self) {
                if (instance.isWatchigHide) {
                    instance.$emit('hide');
                    //instance.appMobileSelect.unmount();
                }
                const overlay = document.querySelector('#calendarOverlay');
                if (overlay && window.innerWidth < 1024) {
                    overlay.classList.remove('active');
                    if (instance.mode !== 'hotel') {
                        document.body.style.overflow = '';
                    }
                }

                // для ПК инпутов hover
                const firstInput = document.querySelector('.search__input_first');
                const secondInput = document.querySelector('.search__input_second');

                firstInput && firstInput.classList.remove('hover');
                secondInput && secondInput.classList.remove('hover');
            },
            showCalendar(event) {
                if (!document.querySelector('#vanillaOverlay')) {
                    const overlay = document.querySelector('#calendarOverlay');
                    if (overlay && window.innerWidth < 1024) {
                        overlay.classList.add('active');
                        document.body.style.overflow = 'hidden';
                    }
                }
                // if (instance.mode === 'hotel') {
                //     document.body.style.overflow = 'hidden';
                // }

                if (!event.selectedDates[0]) {
                    const firstInput = document.querySelector('.search__input_first');

                    if (firstInput) {
                        firstInput.classList.add('hover');
                    }
                }
            },
            updateCalendar(self) {
                instance.$nextTick(() => {
                    instance.ensureFooter();
                    instance.ensureFooterDates();

                });

                const infoClear = document.querySelector('.vanilla-calendar-info__clear');
                if (infoClear) {
                    infoClear.addEventListener('click', () => {
                        instance.store.adultCount = 1;
                        instance.store.children = [];
                        self.update();
                        self.HTMLElement.classList.remove('child-select-open');
                        instance.$nextTick(() => {
                            instance.store.selectedBeginDate = null;
                            instance.store.selectedEndDate = null;
                        })
                    });
                }

                if (window.innerWidth < 1024) {
                    initVueComponent(instance);
                }

                if (!self.selectedDates.length) {
                    // для ПК инпутов
                    const start = document
                        .querySelector('#calendar-input-' + instance.mode)
                        .parentElement.querySelector('#vanilaStart');
                    if (start) {
                        if (start.tagName === 'INPUT') {
                            start.value = '';
                        } else {
                            start.textContent = 'Выберите дату';
                        }
                    }

                    const end = document
                        .querySelector('#calendar-input-' + instance.mode)
                        .parentElement.querySelector('#vanilaEnd');
                    if (end) {
                        if (start.tagName === 'INPUT') {
                            end.value = '';
                        } else {
                            end.textContent = 'Выберите дату';
                        }
                    }
                }
                if (!self.selectedDates.length < 2) {
                    // для мобилки
                    if (['widget-search', 'search'].includes(instance.mode)) {
                        const mobileSelect = document.querySelector('#mobile-select-' + instance.mode);

                        if (mobileSelect) {
                            mobileSelect.textContent = `Выберите дату`;
                        }
                    }
                }
            },
        },
        type: 'multiple',
        settings: {
            lang: 'ru',
            selection: {
                day: 'multiple-ranged',
                month: 'only-arrows',
                year: 'only-arrows',
            },
            selected: {
                dates: instance.selectedDates,
                month: instance.selectedMonth,
                year: instance.selectedYear,
            },
            visibility: {
                positionToInput: 'center',
                daysOutside: false,
            },
            range: {
                min: instance.store.selectedBeginDate ?? null,
                disablePast: true,
                disableGaps: true,
                disabled: instance.totalDisabledDates,
                edgesOnly: true,
            },
        },
        DOMTemplates: {
            multiple: `
      <div class="vanilla-calendar-controls">
        <#ArrowPrev />
        <#ArrowNext />
      </div>
      <div class="vanilla-calendar-grid">
        <#Multiple>
          <div class="vanilla-calendar-column">
            <div class="vanilla-calendar-header">
              <div class="vanilla-calendar-header__content">
                <#Month />
                <#Year />
              </div>
            </div>
            <div class="vanilla-calendar-wrapper">
              <#WeekNumbers />
              <div class="vanilla-calendar-content">
                <#Week />
                <#Days />
              </div>
            </div>
          </div>
        <#/Multiple>
      </div>
      <div class="vanilla-calendar-footer_line"></div>
      <div class="vanilla-calendar-footer">
        <p class="vanilla-calendar-footer__title" id="vanilaCalendarFooter">Выберите дату заезда</p>
        <div class="vanilla-calendar-footer__notice">${instance.footerHTML}</div>
      </div>
    `,
            default: `
      <div class="vanilla-calendar-container">
        <div class="vanilla-calendar-info">
          <div class="vanilla-calendar-info__title">Заезд</div>
          <button class="vanilla-calendar-info__clear">Сбросить</button>
        </div>
        <div class="vanilla-calendar-header">
          <#ArrowPrev />
          <div class="vanilla-calendar-header__content">
            <#Month />
            <#Year />
          </div>
          <#ArrowNext />
        </div>
        <div class="vanilla-calendar-wrapper">
          <#WeekNumbers />
          <div class="vanilla-calendar-content">
            <#Week />
            <#Days />
          </div>
        </div>
        <div class="vanilla-calendar-footer">
          <p class="vanilla-calendar-footer__title" id="vanilaCalendarFooter">Выберите дату заезда</p>
          <div class="vanilla-calendar-footer__notice">${instance.footerHTML}</div>
        </div>
        <div id="mobileChildSelect"></div>
        <div id="mobileChildButton"></div>
      </div>
    `,
        },
        popups: instance.popups,
    };
};

export default {
    data() {
        return {
            calendar: null,
            isMobile: false,
            isCalendarOpen: false,
            isChildSeletOpen: false,
            isWatchingSelectedYearAndMonth: true,
            appMobileSelect: null,
            isWatchigHide: true,
        };
    },
    components: {
        Overlay,
        CalendarWidgetSearch,
        CalendarSearch,
        CalendarHotel,
    },
    props: {
        mode: String,
        store: Object,
        selectedYearAndMonth: {
            type: String,
            default: formatYearAndMonth(new Date().getFullYear(), new Date().getMonth()),
        },
        prices: {
            type: Array,
            default: () => [],
        },
        disabledDates: {
            type: Array,
            default: () => [],
        },
        loading: Boolean,
        footer: {
            type: String,
            default: '',
        },
        errorBeginDate: [Boolean, String],
        errorEndDate: [Boolean, String],
        errorGuest: Boolean,
        guest: Boolean,
    },
    emits: ['updateSelectedYearAndMonth', 'hide', 'init', 'find'],
    computed: {
        selectedDates: function () {
            if (this.store.selectedBeginDate && !this.store.selectedEndDate) {
                return [this.store.selectedBeginDate];
            } else if (this.store.selectedEndDate) {
                return [this.store.selectedBeginDate + ':' + this.store.selectedEndDate];
            } else {
                return [];
            }
        },
        selectedMonth: function () {
            return parseInt(this.selectedYearAndMonth.split('-')[1], 10) - 1;
        },
        selectedYear: function () {
            return parseInt(this.selectedYearAndMonth.split('-')[0], 10);
        },
        totalDisabledDates: function () {
            if (this.loading) {
                const dates = [];
                generateDaysInMonthAndNextMonth(this.selectedYearAndMonth).forEach(date => {
                    dates.push(date);
                });
                return dates;
            } else {
                return this.disabledDates;
            }
        },
        guestForm: function () {
            const count = this.store.adultCount;
            if (count % 10 === 1 && count % 100 !== 11) {
                return `${count} гость`;
            } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
                return `${count} гостя`;
            } else {
                return `${count} гостей`;
            }
        },
        childrenForm: function () {
            const count = this.store.children.length;
            if (count % 10 === 1 && count % 100 !== 11) {
                return `${count} ребенок`;
            } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
                return `${count} ребенка`;
            } else {
                return `${count} детей`;
            }
        },

        hotelFixedText: function () {
            const r = [];
            const dates = [];
            if (this.store.selectedBeginDate) {
                dates.push(formatDate(this.store.selectedBeginDate));
                if (this.store.selectedEndDate) {
                    dates.push(formatDate(this.store.selectedEndDate));
                }
            }
            if (dates.length !== 2) {
                return 'Выберите дату';
            }
            r.push(dates.join('- '));
            if (this.store.adultCount) {
                r.push(this.guestForm);
            }
            if (this.store.children.length) {
                r.push(this.childrenForm);
            }
            return r.join(', ');
        },
        popups: function () {
            const currency = '₽';
            const templateLoading = '<p class="vanilla-calendar-text vanilla-calendar-text_loading"></p>';
            const templatePrice = `<p class="vanilla-calendar-text">{price} ${currency}</p>`;
            const templatePriceMain = `<p class="vanilla-calendar-text vanilla-calendar-text_active">{price} ${currency}</p>`;

            const popups = {};
            const prices = {};
            this.prices.forEach(price => {
                if (!this.disabledDates.includes(price.date)) {
                    prices[price.date] = price;
                }
            });
            generateDaysInMonthAndNextMonth(this.selectedYearAndMonth).forEach(date => {
                if (date < formatDateAsString(new Date())) return;
                if (this.loading) {
                    popups[date] = {
                        html: templateLoading,
                    };
                } else {
                    if (prices[date] !== undefined) {
                        let template = prices[date].main ? templatePriceMain : templatePrice;
                        template = template.replace('{price}', formatCount(prices[date].value));
                        popups[date] = {
                            html: template,
                        };
                    }
                }
            });
            return popups;
        },
        footerHTML: function () {
            if (!this.footer) return '';
            return `
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10 7.5H10.0083M9.16667 10H10V13.3333H10.8333M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
                        stroke="#9E9E9E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <span className="calendar-footer-text">${this.footer}</span>
           `;
        },
    },
    watch: {
        hotelFixedText: {
            handler: function () {
                if (this.mode !== 'hotel') return;
                this.$nextTick(() => {
                    const el = document.getElementById('hotel-fixed-text');
                    if (el) {
                        el.innerHTML = this.hotelFixedText;
                    }
                });
            },
            immediate: true,
        },
        adultCount() {
            if (window.innerWidth < 1024) {
                initVueComponent(this);
            }
        },
        children() {
            if (window.innerWidth < 1024) {
                initVueComponent(this);
            }
        },
        'store.selectedBeginDate': function () {
            if (this.calendar === null) return;
            this.calendar.settings.range.min = this.store.selectedBeginDate;
            this.calendar.update();
        },
        selectedDates: function () {
            if (this.calendar === null) return;
            this.ensureMobileDates();
            this.calendar.settings.selected.dates = this.selectedDates;

            this.$nextTick(() => {
                this.calendar.update({
                    dates: true,
                });
                this.ensureSelectedDates();
                this.ensureClearButton();
            });
        },
        selectedYearAndMonth: function () {
            if (!this.isWatchingSelectedYearAndMonth) return;
            this.calendar.settings.selected.month = this.selectedMonth;
            this.calendar.settings.selected.year = this.selectedYear;
            this.calendar.update({ year: true, month: true });
        },
        totalDisabledDates: {
            handler: function () {
                if (this.calendar) {
                    this.$nextTick(() => {
                        this.calendar.settings.range.disabled = this.totalDisabledDates;
                        this.calendar.update();
                    });
                }
            },
            deep: true,
        },
        popups: {
            handler: function () {
                if (this.calendar === null) return;
                this.calendar.popups = this.popups;
                this.calendar.update({ dates: true });
            },
            deep: true,
        },
        footer: function () {
            this.ensureFooter();
        },
    },
    mounted() {
        this.ensureSelectedDates();
    },
    beforeUnmount() {
        // const overlay = document.querySelector('#calendarOverlay');
        // console.log(overlay)
        // if (overlay) {
        //     if (overlay.classList.contains('active')) {
        //         document.body.style.overflow = '';
        //     }
        //     }
        const mediaQuery = window.matchMedia('(max-width: 1023px)');
        // Проверяем, поддерживается ли addEventListener
        if (mediaQuery.addEventListener) {
            mediaQuery.removeEventListener('change', this.handleMediaChange);
        } else if (mediaQuery.addListener) {
            mediaQuery.removeListener(this.handleMediaChange); // Используем addListener для iOS
        }
        if (this.calendar !== null) {
            this.calendar.destroy();
        }
    },
    methods: {
        onOpen() {
            if (this.calendar === null) {
                this.initCalendar();
            }
            document.querySelector('#calendar-input-' + this.mode).click();
            this.$nextTick(() => {

                if (this.mode === 'hotel') {
                    const calendarEl = document.querySelector('.vanilla-calendar');
                    if (calendarEl.parentElement && calendarEl.parentElement.tagName.toLowerCase() === 'body') {
                        let elBlock = document.querySelector('.hotel-detail');
                        if (!elBlock) {
                            elBlock = document.querySelector('.basket-page')
                        }
                        elBlock.appendChild(calendarEl);
                    }
                    const inputTop = document.querySelector('#calendar-input-' + this.mode).getBoundingClientRect().top;
                    setTimeout(() => {
                        calendarEl.style.top = (inputTop + 20) + 'px';
                    })

                }
            })

            this.ensureFooterDates();
        },
        initCalendar() {
            this.$emit('init');
            // Создаем экземпляр календаря
            this.calendar = new VanillaCalendar('#calendar-input-' + this.mode, getParams(this));
            this.calendar.init(); // Инициализируем календарь

            this.$nextTick(() => {

                this.ensureSelectedDates();
                this.ensureClearButton();

            });

            const mediaQuery = window.matchMedia('(max-width: 1023px)');

            this.handleMediaChange(mediaQuery);

            // Проверяем, поддерживается ли addEventListener
            if (mediaQuery.addEventListener) {
                mediaQuery.addEventListener('change', this.handleMediaChange);
            } else if (mediaQuery.addListener) {
                mediaQuery.addListener(this.handleMediaChange); // Используем addListener для iOS
            }
        },
        ensureSelectedDates() {
            const start = document
                .querySelector('#calendar-input-' + this.mode)
                .parentElement.querySelector('#vanilaStart');
            if (this.store.selectedBeginDate && start) {
                start.textContent = formatDate(this.store.selectedBeginDate);
            }
            const end = document
                .querySelector('#calendar-input-' + this.mode)
                .parentElement.querySelector('#vanilaEnd');
            if (this.store.selectedEndDate && end) {
                end.textContent = formatDate(this.store.selectedEndDate);
            }

            if (['widget-search', 'search'].includes(this.mode)) {
                const mobileSelect = document.querySelector('#mobile-select-' + this.mode);
                if (mobileSelect) {
                    if (this.store.selectedBeginDate && this.store.selectedEndDate) {
                        mobileSelect.textContent = `${formatDate(
                            this.store.selectedBeginDate,
                        )} - ${formatDate(this.store.selectedEndDate)}`;
                    } else {
                        mobileSelect.textContent = `Выберите дату`;
                    }
                }
            }
        },
        ensureFooter() {
            document
                .querySelectorAll('.vanilla-calendar-footer__notice')
                .forEach(el => (el.innerHTML = this.footerHTML));
        },
        ensureFooterDates() {
            const vanilaCalendarFooter = document.querySelector('#vanilaCalendarFooter');

            if (vanilaCalendarFooter) {
                vanilaCalendarFooter.classList.remove('error');
                if (this.calendar.selectedDates[0]) {
                    this.$nextTick(() => {
                        document.querySelector('#vanilaCalendarFooter').textContent = `Заезд ${formatDate(
                            this.calendar.selectedDates[0],
                        )} - Выберите дату выезда`;
                    });
                }
                if (this.calendar.selectedDates[1]) {
                    this.$nextTick(() => {
                        document.querySelector('#vanilaCalendarFooter').textContent = `Заезд ${formatDate(
                            this.calendar.selectedDates[0],
                        )} - выезд ${formatDate(this.calendar.selectedDates[1])}`;
                    });
                }
                if (!this.calendar.selectedDates[0] && !this.calendar.selectedDates[1]) {
                    this.$nextTick(() => {
                        document.querySelector('#vanilaCalendarFooter').textContent = `Выберите дату заезда`;
                    });
                }
            }
        },
        onMobileFindClick() {
            if (this.store.selectedBeginDate && this.store.selectedEndDate) {
                this.$emit('find');
                const overlay = document.querySelector('#calendarOverlay');
                if (overlay) {
                    overlay.classList.remove('active');
                }
                this.calendar.hide();
            } else {
                document.querySelector('#vanilaCalendarFooter').classList.add('error');
            }
        },
        ensureClearButton() {
            const infoClear = document.querySelector('.vanilla-calendar-info__clear');
            if (infoClear) {
                if (this.store.selectedBeginDate || this.store.selectedEndDate) {
                    infoClear.classList.add('active');
                } else {
                    infoClear.classList.remove('active');
                }
            }
        },
        ensureMobileDates() {
            let value = null;
            if (this.store.selectedBeginDate) {
                if (!this.store.selectedEndDate) {
                    value = `${formatDate(this.store.selectedBeginDate)}`;
                } else {
                    value = `${formatDate(this.store.selectedBeginDate)} - ${formatDate(this.store.selectedEndDate)}`;
                }
            }
            this.store.dates = value;
        },

        handleMediaChange(event) {
            this.isMobile = event.matches;
            this.isWatchigHide = false;
            if (event.matches) {
                this.calendar.type = 'default';
                this.calendar.update();
                this.calendar.hide();
            } else {
                this.calendar.type = 'multiple';
                this.canUpdate = true;
                this.calendar.update();
                this.calendar.hide();
            }
            this.isWatchigHide = true;
        },
    },
};
</script>
